import { DestroyRef, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private titleKey = signal('');

  constructor(private translateService: TranslateService, destroyRef: DestroyRef, title: Title) {
    toObservable(this.titleKey)
      .pipe(
        switchMap((key) => this.translateService.get(key)),
        takeUntilDestroyed(destroyRef)
      )
      .subscribe((translatedTitle) => title.setTitle(translatedTitle));
  }

  setTitle(title: string) {
    this.titleKey.set(title);
  }
}
