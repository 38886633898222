import { z } from 'zod';
import { OrganizationSchema, OrganizationSchemaToServer } from 'app/entities/organization/organization.types';
import { SportType } from 'app/services/sport/sport.service';
import { convertDateTimeToIso, convertLocalDateToServer } from 'app/components/util/date-util.service';

export const RoundSchema = z.object({
  id: z.number(),
  name: z.string(),
  defaultRound: z.boolean(),
  resultsPublished: z.boolean(),
});

export const RoundToServerSchema = RoundSchema.extend({
  id: z.number().optional().nullable(),
  resultsPublished: z.boolean().optional(),
});

export const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  programCount: z.number(),
  teamSport: z.boolean(),
  sportType: z.nativeEnum(SportType),
  maxParticipants: z.number().nullable(),
  judgingRulesProfileId: z.string().nullable(),
  categoryCode: z.string(),
  status: z.string().nullable(),
  type: z.string(),
  publishJudgePoints: z.boolean(),
  categoryDefinitionOverride: z.record(z.any()).nullable(),
  judgingRulesOverride: z.record(z.any()).nullable(),
  price1: z.number(),
  price2: z.number(),
  displayOrder: z.number().nullable(),
  rounds: z.array(RoundSchema).optional().nullable().default([]),
});

export const CategoryToServerSchema = CategorySchema.extend({
  rounds: z.array(RoundToServerSchema).optional().nullable().default([]),
});

export const EventApplicationSchema = z.object({
  id: z.number(),
  applicationDate: z.string().pipe(z.coerce.date()),
  club: OrganizationSchema,
  contactName: z.string(),
  contactEmail: z.string(),
  contactPhone: z.string(),
  location: z.string(),
  venueInfo: z.string(),
  applicationText: z.string().optional().nullable(),
  priority: z.number(),
  reservationExists: z.boolean(),
});

export const EventSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  location: z.string().optional().nullable(),
  published: z.boolean(),
  realm: z.string(),
  cancelled: z.boolean(),
  startDate: z.string().pipe(z.coerce.date()),
  endDate: z.string().pipe(z.coerce.date()),
  organizer: OrganizationSchema.optional().nullable(),
  categories: z.array(CategorySchema),
  applications: z.array(EventApplicationSchema).optional().nullable().default([]),
  applicationStartDate: z.string().optional().nullable().pipe(z.coerce.date()),
  applicationEndDate: z.string().optional().nullable().pipe(z.coerce.date()),
  registrationStartDate: z.string().pipe(z.coerce.date()).optional().nullable(),
  registrationEndDate1: z.string().pipe(z.coerce.date()).optional().nullable(),
  registrationEndDate2: z.string().pipe(z.coerce.date()).optional().nullable(),
  settings: z.record(z.any()).optional().nullable().default({}),
  performanceCategory: CategorySchema.optional().nullable(),
});

export const EventToServerSchema = EventSchema.extend({
  startDate: z.date().transform(convertLocalDateToServer),
  endDate: z.date().transform(convertLocalDateToServer),
  organizer: OrganizationSchemaToServer.optional().nullable(),
  applicationStartDate: z.date().transform(convertLocalDateToServer).optional().nullable(),
  applicationEndDate: z.date().transform(convertLocalDateToServer).optional().nullable(),
  registrationStartDate: z.date().transform(convertDateTimeToIso).optional().nullable(),
  registrationEndDate1: z.date().transform(convertLocalDateToServer).optional().nullable(),
  registrationEndDate2: z.date().transform(convertLocalDateToServer).optional().nullable(),
  categories: z.array(CategoryToServerSchema),
});

export type Round = z.infer<typeof RoundSchema>;

export type Category = z.infer<typeof CategorySchema>;

type EventInferred = z.infer<typeof EventSchema>;

export type Event = Required<Pick<EventInferred, 'startDate' | 'endDate'>> &
  Omit<EventInferred, 'startDate' | 'endDate'>;

export type EventToServer = z.infer<typeof EventToServerSchema>;
